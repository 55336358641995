import { gql } from "@apollo/client";

const FACEBOOK_LOGIN = gql`
  mutation FACEBOOK_LOGIN ( $input: FacebookLoginCustomInput!) {
    facebookLoginCustom(input: $input) {
      authToken
      sessionToken
      user {
        databaseId
        userId
        id
        username
        name
        email
        firstName
        lastName
      }
      customer {
        billing {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
        }
        firstName
        email
        lastName
        shipping {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        metaData {
          key
          value
        }
      }
    }
  }
`;

export default FACEBOOK_LOGIN;
