import React, { useState, useRef, useContext } from "react";
// import MessageAlert from "../message-alert";
// import cartSpinnerGif from "../../images/cart-spinner.gif";
import { sanitize, setAuth } from "../../utils/functions";
import { isEmpty } from "lodash";
// import validateAndSanitizeRegisterForm from "../../validator/register";
import { useMutation } from "@apollo/client";
import { v4 } from "uuid";
import REGISTER_CUSTOMER from "../../mutations/register";
import { useForm } from "react-hook-form"
import Spin from "../spin/Spin";
import Button from "../button/Button";
import { AppContext } from "../context/AppContext";

/**
 * Register Functional Component.
 *
 * @return {object} Register form.
 */
const Register = ({
  setLoggedIn,
  heading,
  buttonBack,
  buttonCreate,
  buttonCreateAccount,
  labelUserFirstName,
  labelUserLogin,
  registerLabelUserEmail,
  registelabelUserPassword,
  labelUserPhone,
  labelUserRepeatPassword,
  labelUserSecondName,
  linkBuyWithoutCreateAccount,
  textCheckboxEmail,
  textCheckboxPhone,
  textCheckboxStatute,
  textRodo,
  textUserPasswordInfo,
  textWhyCreateAccount,
  errorRequiredInput,
  errorUserPhone,
  errorUserEmail,
  errorUserPasswordDontMatch,
  errorUserPassword,
  comeFromCart
}) => {
  const { cart } = useContext(AppContext)
  const [showForm, toogleShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false)
  const [registerFields, setRegisterFields] = useState({
    email: '',
    password: "",
    username: "",
    billing: {
      phone: "",
      firstName: "",
      lastName: "",
      email: ""
    },
    metaData: {
      agreementEmail: '0',
      agreementPhone: '0',
      agreementStatute: '0',
    }
  })

  const registerPasswordRef = useRef({});

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  registerPasswordRef.current = watch("registerPassword", "");

  const onSubmit = (values, e) => {
    if (loading) return;
    setRegisterFields({
      email: values.registerEmail,
      password: values.registerPassword,
      username: values.login,
      billing: {
        phone: values.tel,
        firstName: values.name,
        lastName: values.username,
        email: values.registerEmail
      },
      metaData: {
        agreementEmail: values.agreementEmail ? '1' : '0',
        agreementPhone: values.agreementPhone ? '1' : '0',
        agreementStatute: values.agreementStatute ? '1' : '0',
      }
    });
    setErrorMessage('');
    setLoading(true);
    registerAction();
  }

  // Register Mutation.
  const [
    registerAction,
    { loading: registerLoading, error: registerError },
  ] = useMutation(REGISTER_CUSTOMER, {
    variables: {
      input: {
        clientMutationId: v4(), // Generate a unique id.,
        username: registerFields.username,
        password: registerFields.password,
        email: registerFields.email,
        firstName: registerFields.billing.firstName,
        lastName: registerFields.billing.lastName,
        billing: {
          phone: registerFields.billing.phone,
          firstName: registerFields.billing.firstName,
          lastName: registerFields.billing.lastName,
          email: registerFields.billing.email
        },
        metaData: [
          {
            key: 'agreementEmail',
            value: registerFields.metaData.agreementEmail
          },
          {
            key: 'agreementPhone',
            value: registerFields.metaData.agreementPhone
          },
          {
            key: 'agreementStatute',
            value: registerFields.metaData.agreementStatute
          }
        ]
      },
    },

    onCompleted: (data) => {
      // If error.
      if (!isEmpty(registerError)) {
        setErrorMessage(registerError.graphQLErrors[0].message);
      }

      const {
        registerCustomer: { customer },
      } = data;

      // handleRegisterSuccess();

      const authData = {
        authToken: customer.jwtAuthToken,
        user: customer,
      };

      setAuth(authData);
      setLoggedIn(true);
      setLoading(false);
    },

    onError: (error) => {
      if (error) {
        if (!isEmpty(error)) {
          setLoading(false);
          setErrorMessage(error.graphQLErrors[0].message);
        }
      }
    },

  });

  return (
    <div className="register__form">
      {/* Title */}
      <h2 className="loginPage__heading heading--normal heading--dash">{heading}</h2>

      <div className={`loginPage__register__info ${showForm ? "loginPage__register--hidden" : ''}`}>
        <div className="loginPage__button form__button">
          <a href="#" onClick={(e) => { e.preventDefault(); toogleShowForm(true); }}>
            {buttonCreate}
          </a>
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: textWhyCreateAccount }} />
        </div>
        {comeFromCart && !cart.subscription && (
          <div className="loginPage__button loginPage__register__checkout">
            <Button className="btn--secondary" url={linkBuyWithoutCreateAccount.url} title={linkBuyWithoutCreateAccount.title} />
          </div>
        )}
      </div>
      <div className={`loginPage__register__form ${showForm ? "" : 'loginPage__register--hidden'}`}>


        <form className="form loginPage__register__wrapper" onSubmit={handleSubmit(onSubmit)}>
          <div className="form__input">
            <label className="form__label" htmlFor="login">
              {labelUserLogin}
              <span className="form__label__required">*</span>
            </label>
            <input
              id="login"
              type="text"
              {...register("login", {
                required: true,
              })}
            />
            <span className="form__error">{errors.login && errorRequiredInput}</span>
          </div>

          <div className="form__input">
            <label className="form__label" htmlFor="name">
              {labelUserFirstName}
              <span className="form__label__required">*</span>
            </label>
            <input
              id="name"
              type="text"
              {...register("name", {
                required: true,
              })}
            />
            <span className="form__error">{errors.name && errorRequiredInput}</span>
          </div>

          <div className="form__input">
            <label className="form__label" htmlFor="username">
              {labelUserSecondName}
              <span className="form__label__required">*</span>
            </label>
            <input
              id="username"
              type="text"
              {...register("username", {
                required: true,
              })}
            />
            <span className="form__error">{errors.username && errorRequiredInput}</span>
          </div>
          <div className="form__input ">
            <label className="form__label" htmlFor="tel">
              {labelUserPhone}
              <span className="form__label__required">*</span>
            </label>
            <input
              {...register("tel", {
                required: true,
                pattern: {
                  value: /(\+)?(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}/i,
                  message: errorUserPhone
                }
              })}
              id="tel"
              type="tel"
            />
            <div className="form__error"><span>{errors.tel ? (errors.tel.message ? (errors.tel.message) : errorRequiredInput) : ''}</span></div>
          </div>

          <div className="form__input ">
            <label className="form__label" htmlFor="registerEmail">
              {registerLabelUserEmail}
              <span className="form__label__required">*</span>
            </label>

            <input
              {...register("registerEmail", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: errorUserEmail
                }
              })}
              id="registerEmail"
              className="form_data"
              type="email"
              name="registerEmail"
            />
            <div className="form__error"><span>{errors.registerEmail ? (errors.registerEmail.message ? (errors.registerEmail.message) : errorRequiredInput) : ''}</span></div>
          </div>

          <div className="form__input">
            <label className="form__label" htmlFor="registerPassword">
              {registelabelUserPassword}
              <span className="form__label__required">*</span>
            </label>
            <input
              id="registerPassword"
              type="password"
              ref={registerPasswordRef}
              {...register("registerPassword", {
                required: true,
                pattern: {
                  value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.{8,}).*$/i,
                  message: errorUserPassword
                }
              })}
            />
            <span className="form__label__info">{textUserPasswordInfo}</span>
            <div className="form__error"><span>{errors.registerPassword ? (errors.registerPassword.message ? (errors.registerPassword.message) : errorRequiredInput) : ''}</span></div>
          </div>

          <div className="form__input">
            <label className="form__label" htmlFor="repeatPassword">
              {labelUserRepeatPassword}
              <span className="form__label__required">*</span>
            </label>
            <input
              id="repeatPassword"
              type="password"
              {...register("repeatPassword", {
                required: true,
                validate: value =>
                  value === registerPasswordRef.current || errorUserPasswordDontMatch
              })}
            />
            <div className="form__error"><span>{errors.repeatPassword ? (errors.repeatPassword.message ? (errors.repeatPassword.message) : errorRequiredInput) : ''}</span></div>
          </div>

          <div className="form__checkbox">
            <label htmlFor="agreementStatute">
              <input
                id="agreementStatute"
                type="checkbox"
                {...register("agreementStatute", {
                  required: true,
                })}
              />
              <span className="form__checkbox__info" dangerouslySetInnerHTML={{ __html: textCheckboxStatute }} />
              <span className="form__error">{errors.agreementStatute && errorRequiredInput}</span>
            </label>
          </div>

          <div className="form__checkbox">
            <label htmlFor="agreementEmail">
              <input
                id="agreementEmail"
                type="checkbox"
                {...register("agreementEmail", {
                })}
              />
              <span className="form__checkbox__info" dangerouslySetInnerHTML={{ __html: textCheckboxEmail }} />
              <span className="form__error">{errors.agreementEmail && errorRequiredInput}</span>
            </label>
          </div>

          <div className="form__checkbox">
            <label htmlFor="agreementPhone">
              <input
                id="agreementPhone"
                type="checkbox"
                {...register("agreementPhone", {
                })}
              />
              <span className="form__checkbox__info" dangerouslySetInnerHTML={{ __html: textCheckboxPhone }} />
              <span className="form__error">{errors.agreementPhone && errorRequiredInput}</span>
            </label>
          </div>
          <div className="form__info">
            <span dangerouslySetInnerHTML={{ __html: textRodo }} />
          </div>

          <div className="loginPage__button form__button loginPage__register__form__send">
            <button type="submit">{buttonCreateAccount}</button>
            {loading ? <Spin /> : ""}
          </div>

          {!isEmpty(errorMessage) && (
            <div
              className="alert alert-danger"
              dangerouslySetInnerHTML={{ __html: sanitize(errorMessage) }}
            />
          )}

          <div className="loginPage__register__form__back">
            {/* <button type="submit">{buttonBack}</button> */}
            <a className="btn btn--secondary" href="#" onClick={(e) => {
              e.preventDefault();
              toogleShowForm(false);
              if ((typeof window !== "undefined")) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }
            }}>
              {buttonBack}
            </a>
          </div>

        </form>

      </div>
    </div>
  );
};

export default Register;
